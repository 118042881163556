.transparent-div {
    img {
        max-width: 150px;
    }
}

ul {
    list-style-type: none;
    padding: 0;
    margin-top: 1rem;
}

.footer-main {
    padding: 60px 0;
    background: #000000;

    a {
        display: block;
        font-size: 16px;
        color: #dedede;
        opacity: .8;
        font-weight: 600;
        margin: 0;
        cursor: pointer;

        &:hover {
            opacity: 1;
            color: #fff;
            text-decoration: none;
        }
    }

    h3 {
        font-size: 20px;
        letter-spacing: .5px;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0;
    }

    li {
        padding: 3px 0;
    }

    .footer-logo-div {

        img {
            max-width: 200px;
        }

        a {
            font-size: 14px;
        }
    }

    .social-media {

        img {
            height: 22px;
            opacity: .7;

            &:hover {
                opacity: 1;
            }
        }
    }

    span {
        font-size: 14px;
        line-height: 14px;
        color: #dedede;
        opacity: 0.8;

        &:hover {
            color: white;
            opacity: 1;
        }
    }
}

.fixed-bottom {
    z-index: 998 !important;
}

.footer-bottom {
    padding: 30px 0;
    background: #17191a;


}

@media(max-width: 991px) {
    .footer {
        padding-bottom: 50px;
    }

    .subscription-form {
        max-width: 500px;

        input,
        button {
            width: 100%;
        }
    }

    .review-box {
        max-width: 400px;
    }
}